import React from 'react'
import { Formik, Form, Field } from 'formik'
import {Button, Box, Loading} from 'components'
import { useState } from 'react'
import Input from './Input'
import styled from 'styled-components'
import useCustomTranslation from 'lib/customT'
import { postComment } from 'messages/actions'
import { connect } from 'react-redux'

const SubmitButton = styled(Button).attrs({
    small: true,
    transparent: true,
    type: 'submit',
  })`
    position: absolute;
    top: 10px;
    right: 10px;
  `

const NewComment = ({postComment, userId, messageId}) => {

    const [isLoading, setIsLoading] = useState(false)
    const t = useCustomTranslation()
    console.log(userId)
    const handleSubmit = ({comment}, {setFieldValue}) => {
      if (!comment) {
        return
      }
  
      new Promise((resolve, reject) => {
        setIsLoading(true)
        postComment({userId, messageId, comment, resolve, reject})
      }).then(() => {
        setIsLoading(false)
        setFieldValue('comment', '')
      })
    }

  return (
    <Formik initialValues={{comment: ''}} onSubmit={handleSubmit}>
      {({}) => (
        <Form>
          <Box position="relative">
            <Field
              name="comment"
              placeholder={`${t('Skriv en kommentar')} ...`}
              component={Input}
            />
            <SubmitButton>
              {isLoading ? <Loading size={20} /> : t('Send')}
            </SubmitButton>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

const enhancer = connect(null, {postComment: postComment.requested})

export default enhancer(NewComment)
