import React from 'react'
import styled from 'styled-components'
import useCustomTranslation from 'lib/customT'
import { Flex, Text, Box } from 'components'
import NewComment from './NewComment'
import Comment from './Comment'
import { useRef, useState, useEffect } from 'react'
import {takeRight} from 'lodash'
import { getComments, postComment } from 'messages/actions'
import {connect} from 'react-redux'
import { getState, getMessageComments } from 'messages/selectors'
import { createStructuredSelector } from 'reselect'



const Container = styled(Flex).attrs({
    flexDirection: 'column',
    px: 3,
    pt: 3,
  })`
    background: ${(props) => props.theme.colors.secondaryLight};
  `
  
  const Content = styled.div`
    overflow-y: scroll;
    margin-bottom: 10px;
  `
  
  const ShowMore = styled(Box).attrs({
    p: 3,
    pt: 0,
  })`
    text-align: center;
    cursor: pointer;
    color: ${(props) => props.theme.colors.secondary};
  `

const Comments = ({userId, getComments, state, messageId, allComments}) => {
    
    

    const t = useCustomTranslation()
    const content = useRef()
    const [height, setHeight] = useState(0)
    const [moreVisible, setMoreVisible] = useState(false)
    
    const comments = moreVisible ? allComments : takeRight(allComments, 3)
    const hasMore = allComments ? allComments.length > 3 : false

    const showMore = () => {
      if (content.current) {
        setHeight(content.current.clientHeight)
        setMoreVisible(true)
      }
    }
  
    const hideMore = () => {
      setMoreVisible(false)
    }
    
    useEffect(()=> {
      getComments({userId, messageId})
    }, [])

    useEffect(() => {
      if (content.current) {
        content.current.scrollTop = content.current.scrollHeight
      }
    }, [height])

    
  return (
    <Container>
      <Flex mb={3}>
        <Text secondary>{t('Kommentarer')}</Text>
      </Flex>

      {comments.length > 0 && (
        <Content ref={content} style={{height: height > 0 && height}}>
          {hasMore && (
            <ShowMore onClick={moreVisible ? hideMore : showMore}>
              {moreVisible ? 'Vis færre' : 'Vis flere'}
            </ShowMore>
          )}
          {comments.map((comment) => (
            <Comment
              {...comment}
              key={comment.id}
              isSelf={userId === comment.userId}
            />
          ))}
        </Content>
      )}

      <NewComment userId={userId} messageId={messageId}/>
    </Container>
  )
}

const enhancer = connect(createStructuredSelector({
  state: getState,
  allComments: getMessageComments,
}), 
{
  getComments: getComments.requested,
  postComment: postComment.requested,
})

export default enhancer(Comments) 
