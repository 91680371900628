export const module_sponsor_exemptions_users = "module_sponsor_exemptions_users";
export const module_sponsor_exemptions = "module_sponsor_exemptions";
export const module_subscription = "subscriptionmodule";
export const module_sponsor = "module_sponsor";
export const module_onlime = "module_onlime";
export const module_payment = "module_payment";
export const module_payment_mobilepay = "module_payment_mobilepay";
export const module_payment_mobilepaysubscription = "module_payment_mobilepaysubscription";
export const module_payment_kevin = "module_payment_kevin";
export const module_ambassador = "module_ambassador";
export const module_activity = "module_activity";
export const module_chat = "module_chat";
export const module_dashboard = "module_dashboard";
export const module_mobileapp_webinfo = "module_mobileapp_webinfo";
export const module_member_signuplink_showwebbutton = "module_member_signuplink_showwebbutton";
export const module_integrations = "module_integrations";
export const module_member_import_conventus = "module_member_import_conventus";
export const module_member_import_winkas = "module_member_import_winkas";
export const module_club_create = "module_club_create";
export const module_wiiandi_calendar = "module_wiiandi_calendar";
export const module_mobilepaysubscription_createsubscriptionplan_webui = "module_mobilepaysubscription_createsubscriptionplan_webui";
export const module_members_editable = "module_members_editable";
export const module_whitelabelmode = "module_whitelabelmode";
export const module_modules = "module_modules";
export const module_invisible_group = "module_invisible_group";
export const module_free_access_group = "module_free_access_group";
export const module_bug_report = "module_bug_report";
export const module_development_timeline = "module_development_timeline";
export const module_webinar = "module_webinar";
export const module_subscription_end_date_field = "module_subscription_end_date_field";
export const module_mastercard_payment = "module_mastercard_payment";
export const module_myassociation_payment = "module_myassociation_payment";
export const module_department_requests = "module_department_requests";
export const module_document_library = "module_document_library";
export const module_export_household = "module_export_household";
export const module_chat_web = "module_chat_web";
export const module_activity_toggle_participant_status = "module_activity_toggle_participant_status";
export const module_message_template = "module_message_template";
